<template>
    <div class="datafeed-content">
        <div class="datafeed-content__center">
            <div class="datafeed-content__center__inner">
                <h1 class="mb-2 text-6xl font-bold">4O4</h1>
                <p class="font-bold">
                    The page you're looking for is not here.
                    <router-link to="/dashboard" class="base-link">Dashboard</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound",
    };
</script>
